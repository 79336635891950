import clsx from 'clsx';
import Image from '@components/elements/Image';
import Link from 'next/link';
import { reviews } from '@data/reviews';
import { useTranslation } from 'next-i18next';
import styles from '../styles/EmailCollector.module.css';

export function ReviewsCardSection({ reviewsColLeft, reviewsColRight }) {
  const { t: tt } = useTranslation('reviews');

  function highlightedText(content, phrases = []) {
    let parts = [content];
    if (phrases && phrases.length > 0) {
      phrases.forEach((phrase) => {
        parts = parts.flatMap((part) =>
          part.split(phrase).reduce((acc, current, index, array) => {
            if (!current && index === array.length - 1) {
              return acc;
            } // skip the last empty split
            return acc.concat(
              index < array.length - 1
                ? [
                    current,
                    <span
                      className="text-yellow-400 font-semibold"
                      key={phrase + index}
                    >
                      {phrase}
                    </span>,
                  ]
                : [current],
            );
          }, []),
        );
      });
    }
    return parts;
  }

  return (
    <>
      <div className="max-w-[768px] mx-auto">
        <h2 className="mt-0 mb-[32px] text-[40px] font-bold text-white text-center">
          {tt('title')}
        </h2>
        <p className="text-center text-wtf-silver mb-0">{tt('subTitle')}</p>
      </div>
      <div className="w-fit mx-auto flex flex-col md:flex-row gap-[24px]">
        <div className="flex flex-col gap-[24px]">
          {reviewsColLeft.map(({ id, content, highlight, authorImgUrl }) => (
            <article
              key={id}
              className={clsx(
                'max-w-[400px]',
                'p-[24px]',
                'flex flex-col gap-[16px]',
                'bg-[#2a2a2b]',
                'border border-white/20 rounded-[12px]',
              )}
            >
              <h3 className="sr-only">{tt('review.title')}</h3>
              <p className="text-[16px] text-white">
                {highlightedText(content, highlight)}
              </p>
              <footer>
                <img
                  src={authorImgUrl}
                  alt={tt('review.author.imgAlt')}
                  height={32}
                />
              </footer>
            </article>
          ))}
        </div>

        <div className="flex flex-col gap-[24px]">
          {reviewsColRight.map(({ id, content, highlight, authorImgUrl }) => (
            <article
              key={id}
              className={clsx(
                'max-w-[400px]',
                'p-[24px]',
                'flex flex-col gap-[16px]',
                'bg-[#2a2a2b]',
                'border border-white/20 rounded-[12px]',
              )}
            >
              <h3 className="sr-only">{tt('review.title')}</h3>
              <p className="text-[16px] text-white">
                {highlightedText(content, highlight)}
              </p>
              <footer>
                <img
                  src={authorImgUrl}
                  alt={tt('review.author.imgAlt')}
                  height={32}
                />
              </footer>
            </article>
          ))}
        </div>
      </div>

      <div className="flex flex-row justify-center items-center">
        <Link href="/ai-girls" locale="en" className={styles.submitButton}>
          {tt('chatNow')}
        </Link>
      </div>
    </>
  );
}
