import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';
import styles from '../../styles/EmailCollector.module.css';

function getContent() {
  return [
    {
      id: 'create-gf-mobile',
      imgSrc: '/features/mobile-details/create.webp',
      title: 'Create your own AI Girlfriend',
      text: (
        <>
          Design every detail of your AI companion in{' '}
          <span className="text-highlight font-semibold">SpicyGF</span>, from
          appearance to personality! Customize and switch between multiple AI
          girlfriends, always at your side for fun and engaging conversations.
        </>
      ),
    },
    {
      id: 'catalog-gf-mobile',
      imgSrc: '/features/mobile-details/catalog.webp',
      title: 'Enjoy a collection of fantasies',
      text: (
        <>
          Dive into a vast collection of unique roleplay scenarios in{' '}
          <span className="text-highlight font-semibold">SpicyGF</span>, from
          reconnecting with an ex to meeting the girl next door. New characters
          are added daily to keep your fantasies fresh!
        </>
      ),
    },
    {
      id: 'photo-gf-mobile',
      imgSrc: '/features/mobile-details/blurr.jpg',
      title: 'Get spicy photos in chat',
      text: (
        <>
          Unlock exciting{' '}
          <span className="text-highlight font-semibold">spicy selfies </span>
          and outfits from your AI characters! Enjoy flirty chats, roleplays,
          and special moments with personalized interactions and fun dates.
        </>
      ),
    },
  ];
}

export const MobileDetails = () => {
  const router = useRouter();

  return (
    <section className="w-full max-w-screen-lg mx-auto">
      <h2 className="text-center text-[35px] font-bold my-0 mb-[60px]">
        Enjoy Mobile AI Girlfriend: SpicyGF
      </h2>

      <ul className={clsx('list-none p-0 !px-4', 'flex flex-col gap-[48px]')}>
        {getContent().map((content) => (
          <li key={content.id} className="!m-0">
            <article className="flex flex-col md:flex-row md:items-center">
              <div className="h-full md:w-52 md:h-fit !mb-2 md:!mb-0">
                <img
                  src={content.imgSrc}
                  alt={content.title}
                  className="rounded-md"
                />
              </div>

              <div className="w-full md:w-1/2 px-4 md:ml-8">
                <h3 className="text-2xl font-bold mb-[20px]">
                  {content.title}
                </h3>

                <p>{content.text}</p>
              </div>
            </article>
          </li>
        ))}
      </ul>

      <div className="flex flex-row justify-center mt-[96px]">
        <Link
          href="https://apps.apple.com/us/app/ai-girlfriend-roleplay-spicygf/id6708239451?platform=iphone"
          className={styles.submitButton}
        >
          Download Free
        </Link>
      </div>
    </section>
  );
};
