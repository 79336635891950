export const reviews = [
  {
    id: 1,
    content:
      'Cheers brothers! Fantastic work on the AI btw. They have been a bunch of fun',
    highlight: ['a bunch of fun'],
    authorImgUrl: '/reviews/review_1.svg',
  },
  {
    id: 2,
    content:
      'I just tried out the daughter of the yakuza boss. Man that was amazing! Incredible what ai can do! Although she still gave a few wonky answers, I could always lead her back to the convo. Cart wait to get more messages again',
    highlight: ['Man that was amazing! Incredible what ai can do!'],
    authorImgUrl: '/reviews/review_7.svg',
  },

  {
    id: 3,
    content:
      "Guys, i don't know if you've made anything like this before but it's so good, I'm honestly blown away. The conversations feel so genuine. Congratulations. Keep up the good work. I'll stop sucking you off about it now hahaha i'm just super impressed",
    highlight: ["I'm honestly blown away. The conversations feel so genuine."],
    authorImgUrl: '/reviews/review_3.svg',
  },
  {
    id: 4,
    content:
      'I already have one up - the fairy queen one. Also I just want to say great work with the site so far, I’m having a lot of fun with it.',
    highlight: ['I’m having a lot of fun with it.'],
    authorImgUrl: '/reviews/review_2.svg',
  },
  {
    id: 5,
    content: "I can't wait to see what new ai girls are going to be added!!",
    highlight: ['new ai girls are going to be added!!'],
    authorImgUrl: '/reviews/review_5.svg',
  },
  {
    id: 6,
    content:
      "I'm also enjoying the app A LOT. If you look past a few quirks in the Al responses. it feels like cooperative storytelling and makes for freedom in roleplaying that other games just dont offer",
    highlight: ['freedom in roleplaying that other games just dont offer'],
    authorImgUrl: '/reviews/review_6.svg',
  },
  {
    id: 7,
    content:
      "I think it's good. I like it. It's better than most of the apps I use, I would say that the usability on mobile with the browser is one of the your best parts that have no complaints on. Especially since | can move between devices now.",
    highlight: ['better than most of the apps I use'],
    authorImgUrl: '/reviews/review_4.svg',
  },
];
