export const DualAppBanner = () => {
  const apps = [
    {
      name: 'SpicyGf',
      feature: 'Realistic Girls',
      storeLink:
        'https://apps.apple.com/us/app/ai-girlfriend-roleplay-spicygf/id6708239451?platform=iphone',
      iconSrc: '/ios/spicy-gf-icon.png',
    },
    {
      name: 'SweetWaifu',
      feature: 'Anime Girls',
      storeLink:
        'https://apps.apple.com/us/app/sweetwaifu-ai-girlfriend-chat/id6584521658?platform=iphone',
      iconSrc: '/ios/sweet-waifu-icon.png',
    },
  ];

  return (
    <div className="text-center p-4">
      <h2 className="text-wtf-white text-xl mb-4">
        Choose Your Perfect AI Girlfriend!
      </h2>
      <p className="text-wtf-white mb-6">
        Enjoy FREE messages and create your own girlfriend with our apps.
      </p>

      <div className="flex flex-col md:flex-row justify-center items-stretch space-y-6 md:space-y-0 md:space-x-6">
        {apps.map((app, index) => (
          <AppCard key={index} app={app} />
        ))}
      </div>
    </div>
  );
};

const AppCard = ({ app }) => {
  return (
    <div className="p-4 rounded-lg flex flex-col justify-between w-full md:w-64">
      <div>
        <h3 className="text-wtf-white text-lg mb-2">{app.name}</h3>
        <p className="text-wtf-white text-sm mb-4">With {app.feature}</p>
      </div>

      <div className="flex flex-col items-center space-y-4">
        <div className="w-16 h-16 rounded-xl border-2 border-gray-400 shadow-md flex items-center justify-center overflow-hidden">
          <img
            src={app.iconSrc}
            width={70}
            height={70}
            alt={`${app.name} App Icon`}
            className="rounded-lg cursor-pointer"
            onClick={() => window.open(app.storeLink, '_blank')}
          />
        </div>

        <img
          src="/ios/app-store-badge.svg"
          width={150}
          height={60}
          alt={`Download ${app.name} on the App Store`}
          className="cursor-pointer"
          onClick={() => window.open(app.storeLink, '_blank')}
        />
      </div>
    </div>
  );
};
