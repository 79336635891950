import clsx from 'clsx';

/**
 * Re-usable container component that will restrict content's maximum width.
 *
 * Also, it has sensible defaults for horizontal padding.
 *
 * @param props
 * @param {keyof JSX.IntrinsicElements} [props.as]
 * @param {'small' | 'default'} [props.size]
 * @param {string} [props.className]
 *
 * @returns {JSX.Element}
 */
export function Container({
  children,
  size = 'default',
  as = 'div',
  className,
  ...restProps
}) {
  const Tag = as;

  return (
    <Tag
      className={clsx(
        'w-full',
        size === 'small' && 'max-w-[1128px]',
        size === 'default' && 'max-w-[1440px]',
        'mx-auto',
        size === 'default' && 'px-[16px] md:px-[32px] lg:px-[60px]',
        size === 'small' && 'px-[16px] sm:px-[24px]',
        className,
      )}
      {...restProps}
    >
      {children}
    </Tag>
  );
}
